import React, { useEffect } from "react";
import { InlineText, Link, Paragraph, TextContainer, Ul } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection } from "../docs";
import { BaseTable, ColumnHeader, EmptyState, InputField } from "../../lab/src";
import * as scss from "./word-list.scss";

export const Meta: DocPageMeta = {
	category: "Writing",
};

interface Term {
	name: string;
	type: string;
	definition: JSX.Element;
	usage: JSX.Element;
	reverse?: string[];
}

export const Words = {
	// All alternative terms are specified here so those relations don't have
	// to be painstakingly maintained in the term-definitions themselves below.
	AlternativeTerms: [
		["add", "create"],
		["deactivate", "delete", "remove", "clear", "dismiss"],
		["download", "export", "share"],
		["open", "view"],
	],

	// The actual list of words.
	// The order doesn't matter but keeping the list sorted here is probably
	// a good idea for providing a better overview of what's there.
	Terms: [
		{
			name: "activate",
			type: "verb",
			definition: (
				<>
					<Paragraph>a. Makes something work.</Paragraph>
					<br />
					<Paragraph>b. Makes something that has been deactivated work again.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Specify what is being activated, e.g. "Activate policy".</Paragraph>
				</>
			),
			reverse: ["deactivate"],
		},
		{
			name: "add",
			type: "verb",
			definition: (
				<>
					<Paragraph>Moving an existing object into a list, set, or system.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Specify what is being added, e.g. "Add site".</Paragraph>
					<br />
					<Paragraph>
						Specify what is being added to if this is not obvious, e.g. "Add widget to dashboard".
					</Paragraph>
				</>
			),
			reverse: ["remove"],
		},
		{
			name: "back",
			type: "adverb",
			definition: (
				<>
					<Paragraph>Navigates to the previous step in a flow.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Use "Back".</Paragraph>
					<br />
					<Paragraph>
						Specify what you are going back to if this is not obvious, e.g. "Back to overview".
					</Paragraph>
				</>
			),
			reverse: ["next"],
		},
		{
			name: "cancel",
			type: "verb",
			definition: (
				<>
					<Paragraph>Stops the current action.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>
						Use "Cancel". Only specify what you are cancelling if this is not obvious.
					</Paragraph>
				</>
			),
		},
		{
			name: "clear",
			type: "verb",
			definition: (
				<>
					<Paragraph>Removes a selection.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Clear"</Paragraph>
				</>
			),
		},
		{
			name: "close",
			type: "verb",
			definition: (
				<>
					<Paragraph>Hides a component, object or data.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Close network details"</Paragraph>
				</>
			),
			reverse: ["open", "view"],
		},
		{
			name: "copy",
			type: "verb",
			definition: (
				<>
					<Paragraph>Creates an identical instance of the selected object.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Copy dashboard"</Paragraph>
				</>
			),
		},
		{
			name: "create",
			type: "verb",
			definition: (
				<>
					<Paragraph>Generates an object that did not exist before.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Create dashboard"</Paragraph>
				</>
			),
			reverse: ["delete"],
		},
		{
			name: "deactivate",
			type: "verb",
			definition: (
				<>
					<Paragraph>Makes something stop working.</Paragraph>
					<br />
					<Paragraph>An object still exists after being deactivated.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Deactivate policy"</Paragraph>
				</>
			),
			reverse: ["activate"],
		},
		{
			name: "delete",
			type: "verb",
			definition: (
				<>
					<Paragraph>
						a. Destroys an object immediately. Once deleted, the object is unretrievable.
					</Paragraph>
					<br />
					<Paragraph>
						b. Moves an object to a waiting state where it is deleted after a period of time. Once
						deleted, the object is unretrievable.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Delete key metric"</Paragraph>
				</>
			),
			reverse: ["create"],
		},
		{
			name: "dismiss",
			type: "verb",
			definition: (
				<>
					<Paragraph>Marks an object as irrelevant and moves it to a dismissed state.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Dismiss occurrence"</Paragraph>
				</>
			),
		},
		{
			name: "download",
			type: "verb",
			definition: (
				<>
					<Paragraph>
						Extracts an object or data from the Siteimprove product without changing the format.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Download heat map"</Paragraph>
				</>
			),
		},
		{
			name: "edit",
			type: "verb",
			definition: (
				<>
					<Paragraph>a. Allows an object or data to be changed immediately.</Paragraph>
					<br />
					<Paragraph>
						b. Navigates to a page where the user can make changes to a single or multiple objects.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Edit funnel"</Paragraph>
				</>
			),
		},
		{
			name: "export",
			type: "verb",
			definition: (
				<>
					<Paragraph>
						Extracts an object or data from the Siteimprove product while changing its format.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Export to PDF"</Paragraph>
				</>
			),
		},
		{
			name: "give feedback",
			type: "phrase",
			definition: (
				<>
					<Paragraph>Allows the customer to give feedback on something, e.g. a feature.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Give feedback"</Paragraph>
				</>
			),
		},
		{
			name: "join",
			type: "verb",
			definition: (
				<>
					<Paragraph>Allows the user to become part of a program or community.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Join the Firstimprovers program"</Paragraph>
				</>
			),
			reverse: ["leave"],
		},
		{
			name: "leave",
			type: "verb",
			definition: (
				<>
					<Paragraph>Allows the user to no longer be part of a program or community.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Leave the Firstimprovers program"</Paragraph>
				</>
			),
			reverse: ["join"],
		},
		{
			name: "load",
			type: "verb",
			definition: (
				<>
					<Paragraph>Reveals more objects in an already visible list or set of objects.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Load all filters"</Paragraph>
				</>
			),
		},
		{
			name: "mark",
			type: "verb",
			definition: (
				<>
					<Paragraph>a. Allows the user to change the status of an object.</Paragraph>
					<br />
					<Paragraph>b. Allows the user to move an object back to its previous state.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Mark as misspelling"</Paragraph>
				</>
			),
		},
		{
			name: "module",
			type: "noun",
			definition: (
				<>
					<Paragraph>Thematic areas of the Siteimprove product, including:</Paragraph>
					<Ul
						items={[
							"Digital Certainty Index",
							"Accessibility",
							"Quality Assurance",
							"SEO Audit / SEO Professional / SEO Enterprise",
							"Policy",
							"Content Analytics / Marketing Analytics",
							"Data Privacy",
							"Ads",
							"Performance",
						]}
					/>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Accessibility module"</Paragraph>
				</>
			),
		},
		{
			name: "next",
			type: "adjective",
			definition: (
				<>
					<Paragraph>Navigates to the next step in a flow.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Next"</Paragraph>
				</>
			),
			reverse: ["back"],
		},
		{
			name: "open",
			type: "verb",
			definition: (
				<>
					<Paragraph>Opens a file, app or service outside of the Siteimprove product.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Open page in CMS"</Paragraph>
				</>
			),
			reverse: ["close"],
		},
		{
			name: "remove",
			type: "verb",
			definition: (
				<>
					<Paragraph>
						Moves an object out of a list, set, or system. Once removed, the object still exists.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Remove focus issue"</Paragraph>
				</>
			),
			reverse: ["add"],
		},
		{
			name: "resolved",
			type: "adjective",
			definition: (
				<>
					<Paragraph>Issues we didn’t find on a customer’s site.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Resolved issues"</Paragraph>
				</>
			),
		},
		{
			name: "run",
			type: "verb",
			definition: (
				<>
					<Paragraph>Starts an automated process.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Run site check"</Paragraph>
				</>
			),
		},
		{
			name: "schedule",
			type: "verb",
			definition: (
				<>
					<Paragraph>Arranges for something to happen at a particular time.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Schedule report"</Paragraph>
				</>
			),
		},
		{
			name: "send",
			type: "verb",
			definition: (
				<>
					<Paragraph>Transfers something to someone through a system, e.g. email.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Send report"</Paragraph>
				</>
			),
		},
		{
			name: "select",
			type: "verb",
			definition: (
				<>
					<Paragraph>Allows the user to choose one or multiple objects.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Select a site"</Paragraph>
				</>
			),
		},
		{
			name: "set",
			type: "verb",
			definition: (
				<>
					<Paragraph>Settles or decides on something.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Set site target"</Paragraph>
				</>
			),
		},
		{
			name: "share",
			type: "verb",
			definition: (
				<>
					<Paragraph>Copies the URL of a page.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Share page report"</Paragraph>
				</>
			),
		},
		{
			name: "sign in",
			type: "verb",
			definition: (
				<>
					<Paragraph>Logs the user into a portal or platform.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Sign in"</Paragraph>
				</>
			),
			reverse: ["sign out"],
		},
		{
			name: "sign out",
			type: "verb",
			definition: (
				<>
					<Paragraph>Logs the user out of a portal or platform.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Sign out"</Paragraph>
				</>
			),
			reverse: ["sign in"],
		},
		{
			name: "solution",
			type: "noun",
			definition: (
				<>
					<Paragraph>
						A set of modules packaged together for commercial and branding purposes.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "The Inclusivity solution"</Paragraph>
				</>
			),
		},
		{
			name: "undo",
			type: "verb",
			definition: (
				<>
					<Paragraph>Cancels the effect of an action that was just performed.</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "Undo"</Paragraph>
				</>
			),
		},
		{
			name: "view",
			type: "verb",
			definition: (
				<>
					<Paragraph>a. Reveals hidden objects or data.</Paragraph>
					<br />
					<Paragraph>
						b. Navigates to a page in the Siteimprove product that reveals more objects or data.
					</Paragraph>
				</>
			),
			usage: (
				<>
					<Paragraph>Example: "View policy details"</Paragraph>
				</>
			),
			reverse: ["close"],
		},
	],
};

export default (): JSX.Element => {
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [filteredTerms, setFilteredTerms] = React.useState<Term[]>(Words.Terms);

	useEffect(() => {
		// Filter the terms when search-term changes
		const terms = Words.Terms.filter((term) =>
			term.name.toLowerCase().includes(searchTerm.toLowerCase())
		).sort((a, b) => a.name.localeCompare(b.name));
		setFilteredTerms(terms);
	}, [searchTerm]);

	useEffect(() => {
		// We shall bring the target into view both when the page is (re)loaded
		// and when it's navigated back to using the browser's Back-button
		window.addEventListener("load", scrollTargetIntoView);
		window.addEventListener("popstate", scrollTargetIntoView);
		return () => {
			window.removeEventListener("load", scrollTargetIntoView);
			window.removeEventListener("popstate", scrollTargetIntoView);
		};
	}, []);

	// Generate letters from available names
	// Operating on lowercase letters is easier all around, except when
	// showing them - for that we shall (later) show them as uppercase
	const letterSet = new Set<string>();
	Words.Terms.forEach((term) => {
		const letter = term.name.charAt(0).toLowerCase();
		letterSet.add(letter);
	});
	const searchableLetters = Array.from(letterSet).sort();

	const scrollTargetIntoView = () => {
		const hash = window.location.hash;
		if (!hash) return;
		const targetElement = document.querySelector(hash);
		if (!targetElement) return;

		// We actually want to scroll to the target's row so let's find it
		const row = targetElement.closest("TR")!;

		// Bring the row into view so it's not obscured by the search box.
		// The container shall be scrolled so the top of the row is just below
		// the bottom of the search-section.
		const searchBox = document.getElementById("search-section")!.getBoundingClientRect();
		const targetBox = row.getBoundingClientRect();
		const searchBoxHeight = searchBox.top - searchBox.bottom;
		const container = document.getElementById("main-content")!;
		const desiredScrollY = container.scrollTop + searchBoxHeight + targetBox.top;

		// We need to scroll to the desired position at the next update cycle,
		// not immediately.
		// It seems that asking for the very next cycle, ie timeout 0, does not
		// always properly scroll when navigating using the browser's back/forward
		// buttons, as if some other initial scrolling is happening there too; but
		// asking for a non-0 timeout seems to consistently correctly scroll the
		// row to the desired y-position.
		if (desiredScrollY > 0) {
			setTimeout(() => {
				container.scrollTo(0, desiredScrollY);
			}, 1);
		}
	};

	// Place an id 'letter-x' at the first word starying with x, so we can have
	// a more generic way of expressing linking to "the words starting with x"
	const optionalLetterId = (name: string): JSX.Element => {
		const letter = name.charAt(0).toLowerCase();
		const firstName = filteredTerms.find((w) => w.name.toLowerCase().startsWith(letter))?.name;
		return firstName == name ? <span id={`letter-${letter}`} /> : <></>;
	};

	// Make a simple slug for names by replacing whitespace with dashes
	const idForName = (name: string) => `term-${name.replace(/\s+/g, "-")}`;

	// Letters should only be enabled if there are filtered terms with that letter
	const isLetterEnabled = (letter: string): boolean =>
		filteredTerms.some((w) => w.name.toLowerCase().startsWith(letter));

	// Optionally show "Did you mean xxx?" and "The reverse is yyy"
	const showDidYouMeanAndReverseNames = (term: Term): JSX.Element => {
		const name = term.name;
		let alternativeTerms = undefined;
		let reverseTerms = undefined;

		const altTerms = Words.AlternativeTerms.filter((a) => a.includes(name));
		if (altTerms.length > 1) throw new Error(`"${name}" must not be part of multiple alternatives`);
		if (altTerms.length > 0) {
			const alternatives = altTerms[0].filter((a) => a != name).sort();
			if (alternatives.length == 0) throw new Error(`"${name}" cannot be the only alternative`);
			alternativeTerms = <>Did you mean {listOfLinkToTerms(alternatives)}?</>;
		}
		if (term.reverse) {
			reverseTerms = <>The reverse is {listOfLinkToTerms(term.reverse)}.</>;
		}

		return alternativeTerms || reverseTerms ? (
			<Paragraph>
				{alternativeTerms && (
					<>
						<br /> {alternativeTerms}
					</>
				)}
				{reverseTerms && (
					<>
						<br /> {reverseTerms}
					</>
				)}
			</Paragraph>
		) : (
			<></>
		);
	};

	// Links to names are shown subtly, inline and without underline
	const linkToTerm = (name: string): JSX.Element => (
		<Link
			inlineInText
			disableWordBreak
			style={{ textDecoration: "none", padding: "none" }}
			href={`#${idForName(name)}`}
			onClick={() => {
				setSearchTerm("");
				scrollTargetIntoView();
			}}
		>
			{name}
		</Link>
	);

	// Format a list of names grammatically correct and as links
	const listOfLinkToTerms = (terms: string[]): JSX.Element => {
		const n = terms.length;
		switch (n) {
			case 1: {
				// Example: a
				return linkToTerm(terms[0]);
			}
			case 2: {
				// Example: a or b
				return (
					<>
						{linkToTerm(terms[0])} or {linkToTerm(terms[1])}
					</>
				);
			}
			default: {
				// Example: a, b, or c
				return (
					<>
						{terms.map((name: string, index: number) =>
							index < n - 1 ? (
								<>
									{linkToTerm(name)}
									{", "}
								</>
							) : (
								<>
									{"or "}
									{linkToTerm(name)}
								</>
							)
						)}
					</>
				);
			}
		}
	};

	return (
		<>
			<HeaderSection
				title="Word list"
				subTitle="Using words intentionally and consistently is one way we help our users navigate through complexity. This guide contains approved words and how to use them."
			/>
			<ContentSection>
				<TextContainer>
					<Paragraph>
						If you can't find a word that suits your needs then reach out in the Slack channel{" "}
						<Link href="https://siteimprove.slack.com/archives/C01KDNW3QTS" inlineInText>
							#ux_writing
						</Link>
						.
					</Paragraph>
				</TextContainer>
				<div className={scss.searchSection} id="search-section">
					<br />
					<InputField
						aria-label="Search for a word"
						placeholder="Search for a word"
						name="search"
						type="search"
						value={searchTerm}
						onChange={setSearchTerm}
					/>
					<span className={scss.verticalDivider}>&nbsp;</span>
					{searchableLetters.map((letter: string) => (
						<button
							onClick={() => {
								window.location.hash = `#letter-${letter}`;
								scrollTargetIntoView();
							}}
							key={letter}
							className={isLetterEnabled(letter) ? scss.letter : scss.letterDisabled}
							disabled={!isLetterEnabled(letter)}
						>
							<InlineText size="medium">{letter.toUpperCase()}</InlineText>
						</button>
					))}
				</div>
				<BaseTable
					thClassName={scss.wordTableTh}
					tdClassName={scss.wordTableTd}
					items={filteredTerms}
					withoutKeyColumn
					rowKey={(term) => idForName(term.name)}
					columns={[
						{
							header: <ColumnHeader content="Word or phrase" />,
							render: (term) => (
								<TextContainer>
									<Paragraph>
										{optionalLetterId(term.name)}
										<InlineText size="medium" emphasis="strong" id={idForName(term.name)}>
											{term.name}
										</InlineText>
									</Paragraph>
									<InlineText tone="subtle" style={{ fontStyle: "italic" }}>
										{term.type}
									</InlineText>
								</TextContainer>
							),
						},
						{
							header: <ColumnHeader content="Definition" />,
							render: (term) => (
								<TextContainer>
									{term.definition}
									{showDidYouMeanAndReverseNames(term)}
								</TextContainer>
							),
						},
						{
							header: <ColumnHeader content="How to use" />,
							render: (term) => <TextContainer>{term.usage}</TextContainer>,
						},
					]}
					topAligned
					noDataState={
						<EmptyState
							type="reassure"
							heading="No results"
							description="Your search gave no results. Try searching for something else."
						/>
					}
				/>
			</ContentSection>
		</>
	);
};
